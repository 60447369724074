import React from 'react';

export default function LoadingScreen(): React.JSX.Element {
    return (
        <main>
            <div className="chart-container vertical-flex-center">
                <h1>Loading the chart, please wait...</h1>
                <div className="sk-chase">
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                </div>
            </div>
        </main>
    );
}
